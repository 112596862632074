import * as ArrayUtils from '../ArrayUtils';
import * as DateTimeUtils from '../DateTimeUtils';
import * as UnitConversionUtils from '../UnitConversionUtils';
import * as Utils from '../Utils';
import * as FieldTypes from '../types/FieldTypes';
import * as DataModelTypeUtils from '../DataModelTypeUtils';

export function isSelectionlaceHolderReady(selection){
  return selection?.networkElementDataStructure?.id;
}

export function isPlaceholderValid(placeholderValue, validateSelectionFun){
  if (placeholderValue 
    && (placeholderValue.value
    || (placeholderValue.values?.length) 
    || validateSelectionFun(placeholderValue.selection, {}))) {      
    return true;
  } 

  return false;
}

// DEPRECATED - USED ONLY FOR KAFKA BACKWARD COMPATIBILITY
export function getPlaceholdersValuesIfReadyByIds(widgetPlaceholdersIds, 
  templatesPlaceholdersValues){
  const widgetPlaceholdersValues = [];
  
  let areWidgetPlaceholdersReady = true;

  widgetPlaceholdersIds.forEach(placeholderId => {
    const placeholderValue = templatesPlaceholdersValues[placeholderId];

    areWidgetPlaceholdersReady &= isPlaceholderValid(placeholderValue, isSelectionlaceHolderReady);
    if(areWidgetPlaceholdersReady){
      widgetPlaceholdersValues.push(placeholderValue);
    }else{
      return {areWidgetPlaceholdersReady};
    }
  });

  return {widgetPlaceholdersValues, areWidgetPlaceholdersReady};
}


export function getPlaceholdersValuesIfReadyByPlaceholders(widgetPlaceholders = [], 
  allPlaceholders, validateSelectionFun, requiredUsages){
  const widgetPlaceholdersValues = [];
  
  let areWidgetPlaceholdersReady = true;

  widgetPlaceholders?.forEach(placeholder => {
    const placeholderValue = allPlaceholders[placeholder.id];

    const placeholderInRequiredUsages = ArrayUtils.getCommonList(requiredUsages, 
      placeholder.usages);

    areWidgetPlaceholdersReady &= (placeholderInRequiredUsages?.length == 0
      || isPlaceholderValid(placeholderValue, validateSelectionFun));
    if(areWidgetPlaceholdersReady){
      widgetPlaceholdersValues.push(placeholderValue);
    }else{
      return {areWidgetPlaceholdersReady};
    }
  });

  return {widgetPlaceholdersValues, areWidgetPlaceholdersReady};
}

export function constructMatchedBucketExtraFields(matchedBucketExtraFields, aggregatedData, precision){
  const metricExtraFields = [];
  
  if(matchedBucketExtraFields && matchedBucketExtraFields.length > 0 
    && aggregatedData){
    matchedBucketExtraFields.forEach(bucketField => {
      const selectedBucketField  = bucketField.field;
      
      if(selectedBucketField && selectedBucketField.id){
        const fieldName = selectedBucketField.fieldName;

        let value = getFieldValue(fieldName, aggregatedData, precision);

        if(DataModelTypeUtils.isObjectFieldType(selectedBucketField)){
          value = aggregatedData[fieldName] ?? '{}';         
        }else if(FieldTypes.isNonNumericField(selectedBucketField)){
          value = ArrayUtils.joinStrings(value);
        }
        
        metricExtraFields.push(`${Utils.capitalize(fieldName.replace('_', ' '))}: ${value}`);
      }
    });
  }
  
  return metricExtraFields;
}

function getFieldValue(fieldName, dataPoint, precision){
  switch(fieldName){
  case FieldTypes.DATE_FIELD_NAME:
    return DateTimeUtils.epochToCustomDateFormat(
      DateTimeUtils.DEFAULT_DATE_FORMAT, dataPoint[fieldName]);
  case FieldTypes.NETWORK_ELEMENT:
    return dataPoint[fieldName]?.[FieldTypes.NETWORK_ELEMENT_TREE_PATH];
  default:
    if(precision && !isNaN(dataPoint[fieldName])) {
      return UnitConversionUtils.getFinalValueForm(
        Number(dataPoint[fieldName]), undefined, '%', precision, undefined);
    }
    
    return dataPoint[fieldName];
  }
}
